import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Outlet, useParams } from 'react-router'
import { io, Socket } from 'socket.io-client'

import { useUserInfo } from 'src/shared/model/useUser'

import { CabinetButton } from 'src/shared/ui/CabinetButton'

import { ChatInfoType, users } from '../../chatsData'
import { ChatContext } from '../../model/context'
import { CabinetChatInfo } from '../CabinetChatInfo/CabinetChatInfo'
import { CabinetChatList } from '../CabinetChatList/CabinetChatList'
import { UserDetails } from '../UserDetails/UserDetails'

import styles from './CabinetChat.module.scss'

export const CabinetChat: FC = () => {
  const { chatId } = useParams()
  const { user } = useUserInfo()

  const [activeChatId, setActiveChatId] = useState<number | undefined>(Number(chatId))

  const [activeProfile, setActiveProfile] = useState<ChatInfoType>()
  const chatSocketRef = useRef<Socket | null>(null)

  const handleOpenProfile = useCallback((id: number) => {
    const profile = users.find((user) => user.id === id)
    setActiveProfile(profile)
  }, [])

  const handleCloseProfile = useCallback(() => {
    setActiveProfile(undefined)
  }, [])

  useEffect(() => {
    chatSocketRef.current = io('https://market-iq-chat-service-teststand.up.railway.app/', {
      autoConnect: false,
      reconnectionDelayMax: 1000,
      query: {
        userId: user?.id,
        roomId: 'users_room',
      },
    })

    chatSocketRef.current.connect()

    chatSocketRef.current.emit('user:add')
    chatSocketRef.current.emit('active_users:get')

    chatSocketRef.current.on('connect', () => {
      console.log('Соединение с сервером установлено (пользователи чата)')
    })

    chatSocketRef.current.on('exception', (err) => {
      console.error('Ошибка от сервера:', err)
    })

    return () => {
      chatSocketRef.current?.disconnect()
    }
  }, [])

  const onAdd = () => {
    chatSocketRef?.current?.emit('user:add')
  }

  const onGet = () => {
    chatSocketRef?.current?.emit('active_users:get')
  }

  return (
    <ChatContext.Provider value={{ activeChatId, handleOpenProfile, handleCloseProfile, setActiveChatId }}>
      <div className={styles.Chat}>
        <CabinetButton className="" onClick={onAdd}>
          add
        </CabinetButton>
        <CabinetButton className="" onClick={onGet}>
          get
        </CabinetButton>
        <CabinetChatList />
        <Outlet />
        {/* <UserDetails /> */}
        {/* <CabinetChatInfo activeProfile={activeProfile} /> */}
      </div>
    </ChatContext.Provider>
  )
}
