import React, { FC, useCallback, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useInfiniteQuery } from '@tanstack/react-query'
import cn from 'classnames'

import { useInfiniteScroll } from 'src/shared/model/useInfiniteScroll'

import { AdminAPI, OrderListResponseDto } from 'src/shared/api'
import { CollectionType } from 'src/shared/config/types'

import { plural } from 'src/shared/lib/plural'

import { Tabs } from 'src/shared/ui/Tabs'

import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'

import { Order } from '../Order/Order'
import { OrderModal } from '../OrderModal/OrderModal'

import styles from './BloggerCabinet.module.scss'

export const BloggerCabinet: FC = () => {
  const [orderId, setOrderId] = useState<number>()
  const [searchParams, setSearchParams] = useSearchParams()
  const activeTabId = searchParams.get('tab') || 'ALL'
  const tabs = useMemo(
    () => [
      {
        tabId: 'ALL',
        title: 'Все',
      },
      {
        tabId: CollectionType.MANUAL,
        title: 'Отбор по критериям',
      },
      {
        tabId: CollectionType.AUTOMATIC,
        title: 'Гарантированное участие',
      },
    ],
    [],
  )

  const handleTabClick = useCallback(
    (newTabId: CollectionType) => {
      setSearchParams({ tab: newTabId }, { replace: true })
    },
    [setSearchParams],
  )

  const {
    data: ordersData,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ['orders', activeTabId],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await AdminAPI.api.ordersControllerFindAllOrders({
        page: Number(pageParam),
        take: 20,
        collection: activeTabId !== 'ALL' ? activeTabId : undefined,
        _createAt: 'desc',
      })
      return response.data
    },
    getNextPageParam: (lastPage, pages) => {
      const currentPage = pages.length
      const totalPages = Math.ceil(lastPage.total / 20)
      return currentPage < totalPages ? currentPage + 1 : undefined
    },
    initialPageParam: 1,
  })

  const orders = useMemo(
    () => ordersData?.pages.flatMap((page) => page.data) || [],
    [ordersData],
  ) as OrderListResponseDto[]

  const openModal = useCallback((orderId: number) => setOrderId(orderId), [])

  const handleCloseClick = useCallback(() => setOrderId(undefined), [])

  useInfiniteScroll({
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  })

  return (
    <CabinetPageLayout className={styles.Layout}>
      <h1
        className={styles.Title}
      >{`${orders.length} ${plural(['предложение', 'предложения', 'предложений'], orders.length)}`}</h1>
      <Tabs className={styles.Tabs} activeTabId={activeTabId} tabs={tabs} onTabClick={handleTabClick} />

      <div className={styles.Orders}>
        {orders?.map((item) => (
          <Order
            key={item.id}
            photoUrl={item.photoUrl!}
            title={item.title!}
            price={item.price!}
            id={item.id}
            BonusPrice={item.bonusPrice}
            onClick={() => openModal(item.id)}
          />
        ))}
      </div>

      {isFetchingNextPage && <div className={cn('loader', styles.Loader)}></div>}

      {orderId && <OrderModal activeTabId={activeTabId} orderId={orderId} onClose={handleCloseClick} />}
    </CabinetPageLayout>
  )
}
