import { createContext, useContext } from 'react'

interface ChatContextProps {
  activeChatId?: number
  handleOpenProfile: (id: number) => void
  handleCloseProfile: () => void
  setActiveChatId: React.Dispatch<React.SetStateAction<number | undefined>>
}

export const ChatContext = createContext<ChatContextProps | null>(null)

export const useChat = () => {
  const context = useContext(ChatContext)
  if (!context) {
    throw new Error()
  }
  return context
}
