import React from 'react'

import image1 from 'src/shared/assets/cabinet_link_1.png'
import image2 from 'src/shared/assets/cabinet_link_2.png'
import image3 from 'src/shared/assets/cabinet_link_3.png'
import image4 from 'src/shared/assets/cabinet_link_4.png'
import image5 from 'src/shared/assets/cabinet_link_5.png'
import { CABINET_BLOGGERS_INFO_ROUTE, CABINET_ORDER_GUIDE_ROUTE } from 'src/shared/config/consts'

export const QUESTIONS = [
  {
    title: 'Какой результат у меня получится?',
    text: 'Ваш товар и бренд порекомендуют разные микроблогеры у себя в блоге со средним количеством просмотров (охватов) от 1000 - 10 000 в reels&stories. А самое главное выкупят ваш товар по заданному ключевому слову, оставят на МП положительный, развернутый отзыв с фото и видео вашего товара. Деньги платить блогерам не нужно. Каждому блогеру вы предоставите рекламируемый товар, который и является вознаграждением за его труд.',
  },
  {
    title: 'Почему сейчас выгоднее работать с микроблогерами?',
    text: (
      <ol>
        <li>
          1. Доверие: Микроблогеры имеют более лояльную аудиторию. Подписчики чувствуют личное отношение и склонны
          больше доверять мнению блогера.
        </li>
        <br />
        <li>
          2. Большая вовлеченность: Маленькие блогеры чаще общаются с аудиторией, отвечают на комментарии, проводят
          опросы и конкурсы. Это невероятно важно для привлечения внимания к бренду.
        </li>
        <br />
        <li>
          3. Экономия бюджета: Сотрудничество с микроблогерами обходится дешевле, чем с крупными инфлюенсерами, при этом
          приносит те же результаты или даже более высокую конверсию, благодаря более персональному подходу.
        </li>
      </ol>
    ),
  },
  {
    title: 'Сколько стоит одна интеграция с блогером?',
    text: (
      <div className="preLineText">
        Стоимость одной интеграции примерно 400 рублей + ваш товар. Разберем кейс с вашим товаром стоимостью 800 рублей,
        при покупке 50 интеграций на нашем сайте: 20 000 рублей (комиссия сервиса) + сумма 50 ваших товаров для
        блогеров.
        <ol>
          <li>- 50 выкупов блогерами из разных регионов по ключевому запросу с забором из ПВЗ</li>
          <li>- положительный и развернутый отзыв с фото от каждого блогера</li>
          <li>
            - И самое главное - это 50 рекламных интеграций от проверенных блогеров с реальными охватами от 1000
            просмотров и выше в сторис и рилс, с UTM-меткой на ваш товар, а также отметка артикула вашего товара и
            отметка вашего бренда в инст
          </li>
        </ol>
      </div>
    ),
  },
  {
    title: 'Как я могу оплатить за товар блогеру?',
    text: 'После того, как блогер загрузит на сайт для вас подтверждение: скриншот «выкупа по ключевому слову», «фильтра по цене», «доставки в пвз» - вам автоматически придут реквизиты блогеров для перевода ему по номеру карты или по номеру телефона.',
  },
  {
    title: 'Могу ли я сам выбирать блогеров?',
    text: 'Да, помимо автоматического набора блогера на задания, у нас предусмотрен также ручной сбор блогеров, где вы сможете самостоятельно на свое задание принимать или отклонять блогера',
  },
  {
    title: 'В течение какого времени выполнится мое задание, и как я смогу отслеживать действия блогера?',
    text: (
      <div className="preLineText">
        <p>
          Рассмотрим кейс на 50 выкупов: <br />
          Вы размещаете нужное вам количество выкупов, если не хотите собирать сразу 50 выкупов, то можно разбить на
          любое удобное количество для вас по выкупам и дням, например, можно в течение 5 дней по 10 выкупов
        </p>
        <br />
        <ul>
          <li>
            1. Заказы по ключевому слову гарантированы в течение 48 часов после того, как на заказ собрались все блогеры
            и он перешел в статус заказа «Исполнение»
          </li>
          <li>2. Забор заказа с ПВЗ от 7 дней, так как блогеры живут в разных городах России</li>
          <li>
            3. Отзыв, рилс, сторис в течение месяца. Но практика показывает, что рекламу и отзывы выпускают намного
            раньше заявленной даты
          </li>
        </ul>
        <br />
        <p>
          Наш сервис самостоятельно заполняет и генерирует вам таблицу excel со всей отчетностью от блогеров, вы можете
          наблюдать за заполнением на сайте в режиме реального времени.
        </p>
      </div>
    ),
  },
]

export const LINKS = [
  {
    title: 'Блогеры и как мы их выбираем',
    desc: 'Более 70% блогеров отсеиваются при модерации',
    img: image1,
    href: CABINET_BLOGGERS_INFO_ROUTE,
  },
  {
    title: 'Создание заказа и добавление товара',
    desc: 'Оформите заказ и добавьте товары для продвижения – всё в одном месте, быстро и удобно',
    img: image2,
    href: CABINET_ORDER_GUIDE_ROUTE,
  },
  {
    title: 'Телеграм-бот',
    desc: 'Подключите Telegram-бота для удобного управления заказами и оперативного получения уведомлений',
    img: image3,
    href: `${process.env.REACT_APP_TG_BOT_URL}?start=userId=`,
  },
  {
    title: 'Кейсы',
    desc: 'Просмотрите готовые кейсы и успешные примеры для вдохновения и оценки эффективности',
    img: image4,
  },
  {
    title: 'Получить консультацию с экспертом',
    desc: 'Обсудите ваш проект с персональным менеджером и получите ценные советы для достижения лучших результатов',
    img: image5,
    href: `https://t.me/marketiq_support`,
  },
]
