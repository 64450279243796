import Logo from 'src/shared/assets/short_logo.svg'

export enum ChatType {
  SYSTEM = 'SYSTEM',
  PRIVATE = 'PRIVATE',
  GROUP = 'GROUP',
}

export interface Chat {
  id: number
  name: string
  avatar?: string
  lastMessage: string
  lastMessageTime: string
  unreadCount?: number
  type: ChatType
}

export interface Message {
  senderId: number
  sender: string
  text: string
  time: string
  avatar?: string
  role: 'user' | 'assistant' | 'contact'
}
export interface ChatInfoUser {
  id: number
  name: string
  avatar?: string
  lastActivity?: string
}

export interface ChatInfoType {
  id: number
  name: string
  avatar?: string
  phone?: string
  instagram?: string
  telegram?: string
  type: 'group' | 'assistant' | 'personal'
  lastActivity?: string
  users?: ChatInfoUser[]
}

export const chats = [
  {
    id: 1,
    name: 'Техподдержка Marketiq',
    lastMessage: 'Спасибо! Сейчас посмотрю и перешлю деньги',
    lastMessageTime: '2025-02-05T12:00:00.000Z',
    unreadCount: 0,
    type: ChatType.SYSTEM,
    messages: [
      {
        senderId: 0,
        sender: 'Техподдержка Marketiq',
        text: 'Здравствуйте! Чем могу помочь? 😊',
        time: '2024-11-26T17:00:00.000Z',
        role: 'assistant',
      },
      {
        senderId: 1,
        sender: 'Вы',
        text: 'Здравствуйте! У меня возник вопрос по поводу оплаты счета. Я сделал перевод, но статус платежа не обновился.',
        time: '2024-11-26T17:05:30.120Z',
        role: 'user',
      },
      {
        senderId: 0,
        sender: 'Техподдержка Marketiq',
        text: 'Пожалуйста, уточните сумму и дату перевода. Мы проверим информацию. 💳',
        time: '2024-11-26T17:10:15.220Z',
        role: 'assistant',
      },
      {
        senderId: 1,
        sender: 'Вы',
        text: 'Оплата была произведена сегодня утром, сумма 1500 рублей.',
        time: '2024-11-26T17:15:45.300Z',
        role: 'user',
      },
      {
        senderId: 0,
        sender: 'Техподдержка Marketiq',
        text: 'Спасибо! Сейчас посмотрю и перешлю деньги. 🎉',
        time: '2024-11-26T17:23:10.450Z',
        role: 'assistant',
      },
    ] as Message[],
  },
  {
    id: 2,
    name: 'Виктория Тишкина',
    avatar: 'https://i.ibb.co/pjfkbvGj/Frame-1321316402.png',
    lastMessage: 'Спасибо! Сейчас посмотрю и перешлю деньги, подскажите, как правильно это сделать и куда слать?',
    lastMessageTime: '2025-02-04T17:00:00.000Z',
    unreadCount: 2,
    type: ChatType.PRIVATE,
    messages: [
      {
        senderId: 2,
        sender: 'Виктория Тишкина',
        avatar: 'https://i.ibb.co/pjfkbvGj/Frame-1321316402.png',
        text: 'Привет! Как у тебя дела? Надеюсь, все хорошо! 😊',
        time: '2024-11-26T16:30:05.320Z',
        role: 'contact',
      },
      {
        senderId: 1,
        sender: 'Вы',
        avatar: 'https://i.ibb.co/nmYgN3v/default-avatar.png',
        text: 'Привет, Виктория! Да, все отлично. А у тебя как? Давно не общались!',
        time: '2024-11-26T16:35:20.400Z',
        role: 'user',
      },
      {
        senderId: 2,
        sender: 'Виктория Тишкина',
        avatar: 'https://i.ibb.co/pjfkbvGj/Frame-1321316402.png',
        text: 'У меня тоже все хорошо. Я хотела узнать, проверил ли ты документ, который я отправила вчера?',
        time: '2024-11-26T16:50:40.500Z',
        role: 'contact',
      },
      {
        senderId: 1,
        sender: 'Вы',
        avatar: 'https://i.ibb.co/nmYgN3v/default-avatar.png',
        text: 'Да, я его посмотрел. Все выглядит отлично! Я сейчас переведу деньги за работу.',
        time: '2024-11-26T17:20:55.600Z',
        role: 'user',
      },
      {
        senderId: 2,
        sender: 'Виктория Тишкина',
        avatar: 'https://i.ibb.co/pjfkbvGj/Frame-1321316402.png',
        text: 'О, супер! Большое спасибо! 😍',
        time: '2024-11-26T17:23:30.700Z',
        role: 'contact',
      },
    ] as Message[],
  },
  {
    id: 3,
    name: 'Михаил Иванов',
    avatar: 'https://i.ibb.co/mCxBjfDL/image-1.png',
    lastMessage: 'Здравствуйте! Выполнил всё',
    lastMessageTime: '2025-02-03T17:00:00.000Z',
    unreadCount: 1,
    type: ChatType.PRIVATE,
    messages: [
      {
        senderId: 1,
        sender: 'Михаил Иванов',
        avatar: 'https://i.ibb.co/mCxBjfDL/image-1.png',
        text: 'Здравствуйте! Выполнил первый этап задачи. Подготовил отчет, можете ознакомиться.',
        time: '2024-11-26T12:00:00.000Z',
        role: 'contact',
      },
      {
        senderId: 2,
        sender: 'Вы',
        avatar: 'https://i.ibb.co/nmYgN3v/default-avatar.png',
        text: 'Спасибо, Михаил! Сейчас посмотрю и дам обратную связь.',
        time: '2024-11-26T12:10:20.300Z',
        role: 'user',
      },
      {
        senderId: 1,
        sender: 'Михаил Иванов',
        avatar: 'https://i.ibb.co/mCxBjfDL/image-1.png',
        text: 'Буду ждать, надеюсь, все верно. 😊',
        time: '2024-11-26T12:15:35.400Z',
        role: 'contact',
      },
      {
        senderId: 2,
        sender: 'Вы',
        avatar: 'https://i.ibb.co/nmYgN3v/default-avatar.png',
        text: 'Да, в целом все отлично! Только добавьте один пункт в отчете.',
        time: '2024-11-26T12:30:45.500Z',
        role: 'user',
      },
      {
        senderId: 1,
        sender: 'Михаил Иванов',
        avatar: 'https://i.ibb.co/mCxBjfDL/image-1.png',
        text: 'Спасибо! Сейчас поправлю и вышлю заново.',
        time: '2024-11-26T12:35:55.600Z',
        role: 'contact',
      },
    ] as Message[],
  },
  {
    id: 4,
    name: 'Мария Павлова',
    avatar: 'https://i.ibb.co/Z6SxzJnN/image-2.png',
    lastMessage: 'Здравствуйте! Выполнила первый этап задачи',
    lastMessageTime: '2025-02-03T17:00:00.000Z',
    unreadCount: 0,
    type: ChatType.PRIVATE,
    messages: [
      {
        senderId: 3,
        sender: 'Мария Павлова',
        avatar: 'https://i.ibb.co/Z6SxzJnN/image-2.png',
        text: 'Здравствуйте! Выполнила первый этап задачи и отправила на проверку.',
        time: '2024-11-26T11:53:00.000Z',
        role: 'contact',
      },
      {
        senderId: 2,
        sender: 'Вы',
        avatar: 'https://i.ibb.co/nmYgN3v/default-avatar.png',
        text: 'Спасибо, Мария! Сейчас проверю и сообщу результат. 🎯',
        time: '2024-11-26T12:00:30.200Z',
        role: 'user',
      },
      {
        senderId: 3,
        sender: 'Мария Павлова',
        avatar: 'https://i.ibb.co/Z6SxzJnN/image-2.png',
        text: 'Отлично, буду ждать обратную связь! 😊',
        time: '2024-11-26T12:05:45.320Z',
        role: 'contact',
      },
      {
        senderId: 2,
        sender: 'Вы',
        avatar: 'https://i.ibb.co/nmYgN3v/default-avatar.png',
        text: 'Все отлично, только добавьте пару деталей в отчет.',
        time: '2024-11-26T12:15:50.450Z',
        role: 'user',
      },
      {
        senderId: 3,
        sender: 'Мария Павлова',
        avatar: 'https://i.ibb.co/Z6SxzJnN/image-2.png',
        text: 'Поняла, сейчас исправлю и отправлю. 🚀',
        time: '2024-11-26T12:20:55.600Z',
        role: 'contact',
      },
    ] as Message[],
  },
  {
    id: 5,
    name: 'Задание №173106',
    avatar: 'https://i.ibb.co/bRqcs5qr/image-3.png',
    lastMessage: 'Здравствуйте! Выполнила первый этап задачи',
    lastMessageTime: '2024-11-26T10:15:00.000Z',
    unreadCount: 26,
    type: ChatType.GROUP,
    messages: [
      {
        senderId: 4,
        sender: 'Анна Кузнецова',
        text: 'Всем привет! Я выполнила первую часть задания, посмотрите, пожалуйста. 📝',
        time: '2024-11-26T10:15:00.000Z',
        role: 'contact',
      },
      {
        senderId: 5,
        sender: 'Дмитрий Орлов',
        text: 'Привет! Отлично, Анна! Сейчас проверю и отпишусь.',
        time: '2024-11-26T10:20:10.200Z',
        role: 'contact',
      },
      {
        senderId: 2,
        sender: 'Вы',
        text: 'Анна, спасибо! Пока все выглядит хорошо, но есть несколько моментов, которые нужно доработать.',
        time: '2024-11-26T10:35:45.300Z',
        role: 'user',
      },
      {
        senderId: 4,
        sender: 'Анна Кузнецова',
        text: 'Окей, поняла! Сейчас внесу исправления и загружу новый вариант. 🚀',
        time: '2024-11-26T10:40:20.500Z',
        role: 'contact',
      },
      {
        senderId: 5,
        sender: 'Дмитрий Орлов',
        text: 'Ждем обновленную версию! Спасибо за оперативность. 😃',
        time: '2024-11-26T10:45:30.600Z',
        role: 'contact',
      },
    ] as Message[],
  },
  {
    id: 6,
    name: 'Андрей Сидоров',
    avatar: 'https://i.ibb.co/1VCXHRX/Frame-1321316403.png',
    lastMessage: 'Добрый день! Когда будет готов результат?',
    lastMessageTime: '2024-11-26T09:30:00.000Z',
    unreadCount: 5,
    type: ChatType.PRIVATE,
    messages: [
      {
        senderId: 6,
        sender: 'Андрей Сидоров',
        avatar: 'https://i.ibb.co/1VCXHRX/Frame-1321316403.png',
        text: 'Добрый день! Когда будет готов результат по нашему проекту? ⏳',
        time: '2024-11-26T09:30:00.000Z',
        role: 'contact',
      },
      {
        senderId: 2,
        sender: 'Вы',
        text: 'Добрый день, Андрей! \nМы сейчас на финальном этапе тестирования. Думаю, к вечеру все будет готово. 😊',
        time: '2024-11-26T09:45:15.200Z',
        role: 'user',
      },
      {
        senderId: 6,
        sender: 'Андрей Сидоров',
        avatar: 'https://i.ibb.co/1VCXHRX/Frame-1321316403.png',
        text: 'Хорошо, буду ждать! Надеюсь, без сюрпризов. 😅',
        time: '2024-11-26T10:00:25.300Z',
        role: 'contact',
      },
      {
        senderId: 2,
        sender: 'Вы',
        text: 'Не волнуйтесь, все идет по плану! 🚀',
        time: '2024-11-26T10:15:40.400Z',
        role: 'user',
      },
      {
        senderId: 6,
        sender: 'Андрей Сидоров',
        avatar: 'https://i.ibb.co/1VCXHRX/Frame-1321316403.png',
        text: 'Отлично, тогда жду ваш отчет вечером. Спасибо!',
        time: '2024-11-26T10:30:50.500Z',
        role: 'contact',
      },
      {
        senderId: 2,
        sender: 'Вы',
        text: 'Пожалуйста! Мы скоро закончим. 😊',
        time: '2024-11-26T10:45:10.600Z',
        role: 'user',
      },
      {
        senderId: 6,
        sender: 'Андрей Сидоров',
        avatar: 'https://i.ibb.co/1VCXHRX/Frame-1321316403.png',
        text: 'Я понимаю, спасибо за информацию. Хорошего дня! 🌞',
        time: '2024-11-26T11:00:00.700Z',
        role: 'contact',
      },
      {
        senderId: 2,
        sender: 'Вы',
        text: 'Спасибо, и вам! Будем на связи. 👋',
        time: '2024-11-26T11:15:20.800Z',
        role: 'user',
      },
      {
        senderId: 6,
        sender: 'Андрей Сидоров',
        avatar: 'https://i.ibb.co/1VCXHRX/Frame-1321316403.png',
        text: 'Кстати, не забудьте про дополнительные требования, которые я прислал. 😊',
        time: '2024-11-26T11:30:30.900Z',
        role: 'contact',
      },
      {
        senderId: 2,
        sender: 'Вы',
        text: 'Хорошо, все учту. Спасибо за напоминание! 📝',
        time: '2024-11-26T11:45:00.000Z',
        role: 'user',
      },
    ] as Message[],
  },
]

export const users: ChatInfoType[] = [
  {
    id: 1,
    name: 'Техподдержка Marketiq',
    avatar: Logo,
    telegram: '@Marketiq_app_bot',
    type: 'assistant',
    lastActivity: '2025-02-06T17:00:00.000Z',
  },
  {
    id: 2,
    name: 'Виктория Тишкина',
    avatar: 'https://i.ibb.co/pjfkbvGj/Frame-1321316402.png',
    phone: '+7 912 345-67-89',
    instagram: 'victoria_tishkina',
    telegram: '@v_tishkina',
    type: 'personal',
    lastActivity: '2025-02-06T17:00:00.000Z',
  },
  {
    id: 3,
    name: 'Михаил Иванов',
    avatar: 'https://i.ibb.co/mCxBjfDL/image-1.png',
    phone: '+7 911 222-33-44',
    instagram: 'mikhail_ivanov',
    telegram: '@m_ivanov',
    type: 'personal',
    lastActivity: '2025-02-06T17:00:00.000Z',
  },
  {
    id: 4,
    name: 'Мария Павлова',
    avatar: 'https://i.ibb.co/Z6SxzJnN/image-2.png',
    phone: '+7 900 123-45-67',
    instagram: 'maria_pavlova',
    telegram: '@m_pavlova',
    type: 'personal',
    lastActivity: '2025-02-06T17:00:00.000Z',
  },
  {
    id: 4,
    name: 'Андрей Сидоров',
    avatar: 'https://i.ibb.co/1VCXHRX/Frame-1321316403.png',
    phone: '+7 950 987-65-43',
    instagram: 'andrey_sidorov',
    type: 'personal',
    lastActivity: '2025-02-06T17:00:00.000Z',
  },
  {
    id: 5,
    name: 'Задание №173106',
    avatar: 'https://i.ibb.co/bRqcs5qr/image-3.png',
    type: 'group',
    users: [
      { id: 1, name: 'Техподдержка Marketiq', avatar: Logo },
      {
        id: 2,
        name: 'Виктория Тишкина',
        avatar: 'https://i.ibb.co/pjfkbvGj/Frame-1321316402.png',
        lastActivity: '2025-02-07T13:07:00.000Z',
      },
      { id: 3, name: 'Анна Кузнецова', lastActivity: '2025-02-07T12:00:00.000Z' },
      { id: 4, name: 'Дмитрий Орлов', lastActivity: '2025-02-07T11:00:00.000Z' },
      {
        id: 5,
        name: 'Андрей Сидоров',
        avatar: 'https://i.ibb.co/1VCXHRX/Frame-1321316403.png',
        lastActivity: '2025-02-06T17:00:00.000Z',
      },
    ],
  },
  {
    id: 6,
    name: 'Андрей Сидоров',
    phone: '+7 933 111-22-33',
    instagram: 'sidorovvvv',
    type: 'personal',
    lastActivity: '2025-02-06T17:00:00.000Z',
  },
]
