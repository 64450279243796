import React, { createContext, useContext, useEffect, useRef } from 'react'
import { io, Socket } from 'socket.io-client'

import { useUserInfo } from 'src/shared/model/useUser'

import { RoleType } from 'src/shared/config/types'

export const SocketContext = createContext<Socket | null>(null)

export const SocketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const socketRef = useRef<Socket | null>(null)
  const { user } = useUserInfo()
  const role = user?.role as RoleType

  // useEffect(() => {
  //   socketRef.current = io(`${process.env.REACT_APP_SERVER_PROXY_URL}/marketiq`, {
  //     autoConnect: false,
  //     reconnectionDelayMax: 1000,
  //     query: {
  //       userId: user?.id,
  //       role,
  //     },
  //   })

  //   socketRef.current.connect()

  //   socketRef.current.on('connect', () => {
  //     console.log('Соединение с сервером установлено (уведомления)')
  //   })

  //   socketRef.current.on('exception', (err) => {
  //     console.error('Ошибка от сервера:', err)
  //   })

  //   return () => {
  //     socketRef.current?.disconnect()
  //   }
  // }, [])

  // useEffect(() => {
  //   socketRef.current = io('https://market-iq-chat-service-teststand.up.railway.app/', {
  //     autoConnect: false,
  //     reconnectionDelayMax: 1000,
  //     query: {
  //       userId: user?.id,
  //       roomId: 'users_room',
  //     },
  //   })

  //   socketRef.current.connect()

  //   socketRef.current.emit('user:add')
  //   socketRef.current.emit('active_users:get')

  //   socketRef.current.on('connect', () => {
  //     console.log('Соединение с сервером установлено (пользователи чата)')
  //   })

  //   socketRef.current.on('exception', (err) => {
  //     console.error('Ошибка от сервера:', err)
  //   })

  //   return () => {
  //     socketRef.current?.disconnect()
  //   }
  // }, [])

  return <SocketContext.Provider value={socketRef.current}>{children}</SocketContext.Provider>
}

export const useSocket = () => {
  const context = useContext(SocketContext)
  // if (!context) {
  //   throw new Error('useSocket must be used within a SocketProvider')
  // }
  return context
}
