import React, { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import { format, isThisWeek, isToday } from 'date-fns'
import { ru } from 'date-fns/locale/ru'

import { ReactComponent as Group } from 'src/shared/assets/group.svg'
import { ReactComponent as Pin } from 'src/shared/assets/pin.svg'
import Logo from 'src/shared/assets/short_logo.svg'
import { CABINET_CHATS_ROUTE } from 'src/shared/config/consts'

import { Avatar } from 'src/shared/ui/Avatar'

import { Search } from 'src/widgets/Search'

import { chats, ChatType } from '../../chatsData'
import { useChat } from '../../model/context'

import styles from './CabinetChatList.module.scss'

export const CabinetChatList: FC = () => {
  const { activeChatId, setActiveChatId, handleOpenProfile } = useChat()
  const [search, setSearch] = useState('')

  return (
    <div className={styles.ChatList}>
      <div className={styles.Search}>
        <Search placeholder="Поиск" setSearchValue={setSearch} />
      </div>
      {chats.map((chat) => {
        const isSystemChat = chat.type === ChatType.SYSTEM
        const isGroupChat = chat.type === ChatType.GROUP
        const isActiveChatId = activeChatId === chat.id

        let timeFormat = 'dd.MM.yyyy'

        if (isThisWeek(chat.lastMessageTime)) timeFormat = 'EEEEEE'
        if (isToday(chat.lastMessageTime)) timeFormat = 'HH:mm'

        return (
          <Link
            to={`${CABINET_CHATS_ROUTE}/${chat.id}`}
            className={cn(
              styles.ChatItem,
              isSystemChat && styles.ChatItem_system,
              isActiveChatId && styles.ChatItem_active,
            )}
            key={chat.id}
            onClick={() => {
              setActiveChatId(chat.id)
              handleOpenProfile(chat.id)
            }}
          >
            <Avatar
              avatar={chat.avatar ?? Logo}
              className={cn(styles.ChatItem__Avatar, chat.type === ChatType.SYSTEM && styles.ChatItem__Avatar_system)}
              firstName={chat.name}
            />
            <div className={styles.ChatItem__Content}>
              <div className={styles.ChatItem__Header}>
                <div className={styles.ChatItem__Name}>
                  {isGroupChat && <Group className={styles.ChatItem__Group} />}
                  <span>{chat.name}</span>
                </div>
                <div className={styles.ChatItem__Time}>{format(chat.lastMessageTime, timeFormat, { locale: ru })}</div>
              </div>
              <div className={styles.ChatItem__Preview}>
                <div className={styles.ChatItem__LastMessage}>{chat.lastMessage}</div>
                {Boolean(chat.unreadCount) && <div className={styles.ChatItem__UnreadCount}>{chat.unreadCount}</div>}
                {isSystemChat && <Pin className={styles.ChatItem__Pin} />}
              </div>
            </div>
          </Link>
        )
      })}
    </div>
  )
}
