import React, { FC, useMemo } from 'react'
import cn from 'classnames'
import { format, isToday, isYesterday, parseISO } from 'date-fns'

import { ReactComponent as Logo } from 'src/shared/assets/short_logo.svg'

import styles from './Notification.module.scss'

export type NotificationKind = 'PLATFORM' | 'TECHNICAL'

export type NotificationType = {
  id: number
  createAt: string
  message: string
  title: string
  notificationType: NotificationKind
  userId?: number
  image?: string
  video?: string
  className?: string
}

export const Notification: FC<NotificationType> = ({
  className,
  notificationType,
  createAt,
  title,
  message,
  image,
}) => {
  const formattedDateTime = useMemo(() => {
    const parsedDate = parseISO(createAt)
    const time = format(parsedDate, 'HH:mm')

    if (isToday(parsedDate) || isYesterday(parsedDate)) {
      return { time }
    }
    const date = format(parsedDate, 'dd.MM.yyyy')

    return { time, date }
  }, [createAt])

  return (
    <div
      className={cn(
        styles.Notification,
        notificationType === 'PLATFORM' ? styles.NotificationSystem : styles.NotificationBase,
        className,
      )}
    >
      <div className={styles.NotificationImg}>
        <Logo className={styles.NotificationLogo} />
      </div>

      <div className={styles.NotificationWrap}>
        <div className={styles.NotificationMain}>
          <pre className={styles.NotificationMainText}>{message}</pre>
          <div className={styles.NotificationMainType}>{title}</div>
        </div>
        <div className={styles.NotificationTime}>
          <span>{formattedDateTime.time}</span>
          <br />
          <span>{formattedDateTime.date}</span>
        </div>
      </div>
    </div>
  )
}
