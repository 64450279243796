import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import cn from 'classnames'
import { format } from 'date-fns'

import { useUserInfo } from 'src/shared/model/useUser'

import { ReactComponent as Clip } from 'src/shared/assets/clip.svg'
import { ReactComponent as Send } from 'src/shared/assets/send.svg'
import Logo from 'src/shared/assets/short_logo.svg'

import useAutosizeTextArea from 'src/shared/lib/useAutosizeTextarea'

import { Avatar } from 'src/shared/ui/Avatar'

import { chats, Message } from '../../chatsData'
import { useChat } from '../../model/context'

import styles from './CabinetChatMain.module.scss'

export const CabinetChatMain: FC = () => {
  const [messages, setMessages] = useState<Message[]>([])
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const [value, setValue] = useState('')

  useAutosizeTextArea(textareaRef.current, value)

  const { handleOpenProfile } = useChat()
  const { chatId } = useParams()
  const { user } = useUserInfo()

  useEffect(() => {
    if (chatId) {
      const nextMessages = chats.find((chat) => chat.id === Number(chatId))?.messages
      setMessages(nextMessages || [])
    }
  }, [chatId])

  const handleChange = useCallback((evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(evt.target?.value)
  }, [])

  const sendMessage = useCallback(() => {
    if (user && value.length >= 1) {
      const nextMessages = [...messages]
      nextMessages.push({
        role: 'user',
        sender: 'Вы',
        senderId: user.id,
        text: value,
        time: new Date().toISOString(),
      })
      setMessages(nextMessages)
      setValue('')
    }
  }, [value, messages, setMessages])

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      sendMessage()
    }
  }

  return (
    <div className={styles.ChatMain}>
      <div className={styles.ChatMain__Messages}>
        {messages.map((message: Message, index) => {
          let avatar = message.avatar

          if (message.role === 'user') avatar = user?.avatar
          if (message.role === 'assistant') avatar = Logo

          return (
            <div className={styles.ChatMessage} key={index}>
              <Avatar
                avatar={avatar}
                firstName={message.sender}
                className={cn(
                  styles.ChatMessage__Avatar,
                  message.role === 'assistant' && styles.ChatMessage__Avatar_system,
                )}
                onClick={() => handleOpenProfile(message.senderId)}
              />
              <div className={styles.ChatMessage__Content}>
                <div className={styles.ChatMessage__Name}>
                  <span>{message.sender}</span>
                  <div className={styles.ChatMessage__Time}>{format(message.time, 'kk:mm')}</div>
                </div>
                <pre className={styles.ChatMessage__Text}>{message.text}</pre>
              </div>
            </div>
          )
        })}
      </div>
      <div className={styles.ChatInput}>
        <label className={styles.ChatInput__Clip} htmlFor="fileUpload" tabIndex={0}>
          <Clip />
        </label>
        <input id="fileUpload" name="fileUpload" type="file" className="visibility-hidden" />
        <textarea
          id="ChatInput"
          ref={textareaRef}
          className={styles.ChatInput__Field}
          placeholder="Отправить сообщение"
          onChange={handleChange}
          value={value}
          rows={1}
          onKeyDown={handleKeyDown}
        />
        <button className={styles.ChatInput__Send} onClick={sendMessage}>
          <Send />
        </button>
      </div>
    </div>
  )
}
