import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import cn from 'classnames'

import { useInfiniteScroll } from 'src/shared/model/useInfiniteScroll'

import { AdminAPI, UserListResponseDto } from 'src/shared/api'
import { CABINET_USERS_ROUTE } from 'src/shared/config/consts'
import { RoleType, UserStatusType } from 'src/shared/config/types'

import { plural } from 'src/shared/lib/plural'

import { Blogger } from 'src/shared/ui/Blogger/Blogger'
import { CabinetActionButton } from 'src/shared/ui/CabinetActionButton'
import { Customer } from 'src/shared/ui/Customer/Customer'
import { Flex } from 'src/shared/ui/Flex'
import { Tabs } from 'src/shared/ui/Tabs'

import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'
import { RejectModal } from 'src/widgets/RejectModal'
import { Search } from 'src/widgets/Search'

import { useChangeRequestStatusMutation } from '../../api/useChangeRequestStatusMutation'
import { useUpdateUserMutation } from '../../api/useUpdateUserMutation'
import { getTabs, TabType } from '../../config/tabs'
import { useUpdateUser } from '../../model/useUpdateUser'

import styles from './AdminCabinetUsers.module.scss'

export type updateUserStatusType = {
  userId: number
  data: { status: UserStatusType; comment?: string }
  message: { loading: string; success: string; error: string }
}

export type updateChangeRequestStatusType = {
  userId: number
  status: 'CONFIRMED' | 'REJECTED' | string
  message: { loading: string; success: string; error: string }
}

export const AdminCabinetUsers: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const queryClient = useQueryClient()

  const activeTabId = searchParams.get('tab') || TabType.VERIFICATION_REQUESTS
  const [usersCounts, setUsersCounts] = useState<Record<string, number>>({})
  const [bloggerToReject, setBloggerToReject] = useState<UserListResponseDto>()
  const [search, setSearchValue] = useState('')

  const navigate = useNavigate()
  const openUser = useCallback((id: number) => navigate(`${CABINET_USERS_ROUTE}/${id}`), [navigate])
  const openUserWithTab = useCallback(
    (id: number, tab: string) => navigate(`${CABINET_USERS_ROUTE}/${id}?tab=${tab}`),
    [navigate],
  )

  const handleTabClick = useCallback(
    (newTabId: TabType) => {
      setSearchParams({ tab: newTabId }, { replace: true })
    },
    [setSearchParams],
  )

  const role = useMemo(() => {
    switch (activeTabId) {
      case TabType.CUSTOMERS:
      case TabType.BLOCKED_CUSTOMERS:
        return RoleType.CUSTOMER
      default:
        return RoleType.BLOGER
    }
  }, [activeTabId])

  const status = useMemo(() => {
    switch (activeTabId) {
      case TabType.VERIFICATION_REQUESTS:
        return UserStatusType.NOT_ACTIVATED
      case TabType.BLOGGERS:
      case TabType.UPDATE_REQUESTS:
      case TabType.CUSTOMERS:
        return UserStatusType.ACTIVATED
      case TabType.TEMPORARY_BLOCKED_BLOGGERS:
        return UserStatusType.TEMPORARY_BLOCKED
      default:
        return UserStatusType.CANCELED
    }
  }, [activeTabId])

  const isFullInfo = activeTabId === TabType.VERIFICATION_REQUESTS ? true : undefined

  const isUpdateRequests = activeTabId === TabType.UPDATE_REQUESTS

  const {
    data: usersData,
    isLoading: isGetUsersLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ['users', activeTabId, search],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await AdminAPI.api.usersManagementControllerGetAllUsers({
        page: Number(pageParam),
        take: 20,
        role,
        status,
        isFullInfo,
        search,
        isApproveChanges: isUpdateRequests,
        _createAt: 'desc',
      })
      return response.data
    },
    getNextPageParam: (lastPage, pages) => {
      const currentPage = pages.length
      const totalPages = Math.ceil(lastPage.count / 20)
      return currentPage < totalPages ? currentPage + 1 : undefined
    },
    initialPageParam: 1,
  })

  const users = useMemo(() => usersData?.pages.flatMap((page) => page.data), [usersData]) as UserListResponseDto[]

  const { mutate: updateUserMutation, isPending: isUpdateUserLoading } = useUpdateUserMutation(queryClient)

  const { mutate: updateChangeRequestStatusMutation, isPending: isUpdateUserStatusLoading } =
    useChangeRequestStatusMutation(queryClient)

  const isLoading = isUpdateUserLoading || isUpdateUserStatusLoading || isGetUsersLoading

  const updateUser = useCallback(
    ({ userId, data, message }: updateUserStatusType) => {
      const updateUserToastId = toast.loading(message.loading)

      updateUserMutation(
        { userId, data },
        {
          onSuccess: () => {
            toast.success(message.success, { id: updateUserToastId })
          },
          onError: (data) => {
            toast.error(data.error?.message || message.error, {
              id: updateUserToastId,
            })
          },
        },
      )
    },
    [updateUserMutation],
  )

  const updateChangeRequestStatus = useCallback(
    ({ userId, status, message }: updateChangeRequestStatusType) => {
      const updateUserToastId = toast.loading(message.loading)

      updateChangeRequestStatusMutation(
        { userId, data: { status } },
        {
          onSuccess: () => {
            toast.success(message.success, { id: updateUserToastId })
            queryClient.invalidateQueries({ queryKey: ['users', activeTabId, search] })
          },
          onError: (data) => {
            toast.error(data.error?.message || message.error, {
              id: updateUserToastId,
            })
          },
        },
      )
    },
    [updateChangeRequestStatusMutation],
  )

  const {
    activateUser,
    sendUserForImprovement,
    rejectUser,
    blockUser,
    unblockUser,
    confirmChangeRequest,
    rejectChangeRequest,
  } = useUpdateUser({
    updateUser,
    updateChangeRequestStatus,
  })

  useEffect(() => {
    if (usersData?.pages[0]) {
      const { data, ...newUsersCounts } = usersData.pages[0]

      setUsersCounts(newUsersCounts)
    }
  }, [usersData])

  const usersTotal = usersCounts.total

  const renderUser = useCallback(
    (user: UserListResponseDto) => {
      const userId = user.id
      const key = `${activeTabId}-${userId}`

      const handleOpenUser = () => openUser(userId)

      if (activeTabId === TabType.VERIFICATION_REQUESTS) {
        return (
          <Blogger key={key} {...user} onClick={handleOpenUser}>
            <Flex flexDirection="column" gap={8}>
              <CabinetActionButton
                kind="primary"
                disabled={isLoading}
                onClick={(e) => {
                  e.stopPropagation()
                  activateUser(userId)
                }}
              >
                Подтвердить
              </CabinetActionButton>
              <CabinetActionButton
                kind="ghost"
                disabled={isLoading}
                onClick={(e) => {
                  e.stopPropagation()
                  setBloggerToReject(user)
                }}
              >
                Отклонить
              </CabinetActionButton>
            </Flex>
          </Blogger>
        )
      }

      if (activeTabId === TabType.UPDATE_REQUESTS) {
        return (
          <Blogger key={key} {...user} onClick={() => openUserWithTab(userId, 'UPDATE_REQUEST')}>
            <Flex flexDirection="column" gap={8}>
              <CabinetActionButton
                kind="primary"
                disabled={isLoading}
                onClick={(e) => {
                  e.stopPropagation()
                  confirmChangeRequest(userId)
                }}
              >
                Подтвердить
              </CabinetActionButton>
              <CabinetActionButton
                kind="ghost"
                disabled={isLoading}
                onClick={(e) => {
                  e.stopPropagation()
                  rejectChangeRequest(userId)
                }}
              >
                Отклонить
              </CabinetActionButton>
            </Flex>
          </Blogger>
        )
      }

      if (activeTabId === TabType.BLOGGERS || activeTabId === TabType.CUSTOMERS) {
        const Component = activeTabId === TabType.BLOGGERS ? Blogger : Customer

        return (
          <Component key={key} {...user} onClick={handleOpenUser}>
            <CabinetActionButton
              kind="ghost"
              disabled={isLoading}
              onClick={(e) => {
                e.stopPropagation()
                blockUser(userId)
              }}
            >
              Отправить в ЧС
            </CabinetActionButton>
          </Component>
        )
      }

      if (
        activeTabId === TabType.BLOCKED_BLOGGERS ||
        activeTabId === TabType.BLOCKED_CUSTOMERS ||
        activeTabId === TabType.TEMPORARY_BLOCKED_BLOGGERS
      ) {
        const Component =
          activeTabId === TabType.BLOCKED_BLOGGERS || activeTabId === TabType.TEMPORARY_BLOCKED_BLOGGERS
            ? Blogger
            : Customer

        return (
          <Component key={key} {...user} onClick={handleOpenUser}>
            <CabinetActionButton
              kind="ghost"
              disabled={isLoading}
              onClick={(e) => {
                e.stopPropagation()
                unblockUser(userId)
              }}
            >
              Разблокировать
            </CabinetActionButton>
          </Component>
        )
      }

      return undefined
    },
    [activeTabId, updateUserMutation, isLoading],
  )

  const handleSendUserForImprovement = useCallback(
    (comment: string) => {
      sendUserForImprovement(bloggerToReject!.id, comment)
      setBloggerToReject(undefined)
    },
    [bloggerToReject],
  )

  const handleRejectUser = useCallback(
    (comment: string) => {
      rejectUser(bloggerToReject!.id, comment)
      setBloggerToReject(undefined)
    },
    [bloggerToReject],
  )

  const handleRejectModalClose = useCallback(() => setBloggerToReject(undefined), [])

  useInfiniteScroll({
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  })

  return (
    <CabinetPageLayout>
      <h1 className={styles.Title}>
        {usersTotal ? `${usersTotal} ${plural(['анкета', 'анкеты', 'анкет'], usersTotal)}` : '* Анкет'}
      </h1>
      <Tabs className={styles.Tabs} activeTabId={activeTabId} tabs={getTabs(usersCounts)} onTabClick={handleTabClick} />
      <Search setSearchValue={setSearchValue} placeholder="Поиск по никнейму Instagram, Telegram или имени" />
      <Flex className={styles.Users} flexWrap="wrap" gap={16}>
        {users?.map(renderUser)}
      </Flex>
      {isFetchingNextPage && <div className={cn('loader', styles.Loader)}></div>}

      {bloggerToReject && (
        <RejectModal
          blogger={bloggerToReject}
          onSendForImprovement={handleSendUserForImprovement}
          onBlock={handleRejectUser}
          onClose={handleRejectModalClose}
        />
      )}
    </CabinetPageLayout>
  )
}
